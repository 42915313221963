import "./style.css";
import background from "./assets/Coming Soon.jpg";
import logo from "./assets/coming-soon-logo.svg";

function App() {
  return (
    <div className="app-container">
      <div className="background-overlay"></div>
      <img src={background} alt="Coming Soon" className="background-image" />
      <div className="content-container">
        <img src={logo} alt="logo" className="logo" />
        <div className="text-container">
          <h2 className="coming-soon-text">Coming Soon</h2>
          {/* Uncomment and style as needed */}
          {/* <div className="notification-section">
            <p className="notification-text">{trans("getNotified")}</p>
            <Form className="notification-form">
              <EmailInput
                name="email"
                placeholder="Example@gmail.com"
                id="coming-soon-email"
                className="email-input"
              />
              <SubmitButton className="submit-button">
                {trans("send")}
              </SubmitButton>
            </Form>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default App;
